import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="K" transform="translate(-36, -65)">
        <path
          d="M 104.82918,130.86659 H 93.146177 L 82.108091,116.083 79.87567,118.78671 v 12.07988 h -9.525 V 93.932413 h 9.525 V 110.65077 L 93.071763,93.932413 H 104.10985 L 89.673521,111.02284 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;

import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(-47, -67)">
          <path
            d="M 104.82918,130.86659 H 93.146177 L 82.108091,116.083 79.87567,118.78671 v 12.07988 h -9.525 V 93.932413 h 9.525 V 110.65077 L 93.071763,93.932413 H 104.10985 L 89.673521,111.02284 Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
